
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        marketplace_item: {
          city: {
          }
        }
      },
    },
    isLanding: false,
  },
  data() {
    return {};
  },
  methods: {
    goTo() {
      window.open(
        `${process.env.BASE_COMMUNITY}mbls/detail/${this.data.communities_content.community.id}/${this.data.id}`,
        "_self"
      );
    },
  },
};
