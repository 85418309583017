
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      boxAppear: true,
    };
  },
  mounted() {
    window.addEventListener("keyup", (e) => {
      if (e.key.toLowerCase() === "escape") this.onClose();
    });
  },
  methods: {
    onClose() {
      this.boxAppear = false;
      setTimeout(() => {
        this.$emit("close");
        this.boxAppear = true;
      }, 250);
    },
  },
};
