
import { communityLinkIcon } from "~/resource/static";
export default {
  props: ["title", "icon_id", "link"],
  data: () => ({
    icon: communityLinkIcon,
  }),
  mouted() {},
  methods: {
    openLink() {
      window.open(this.link, "_blank");
    },
  },
};
