
import { positionIcon } from "~/resource/static.js";
export default {
  props: ["id", "position", "activeUserPosition"],
  data: () => ({
    canBeDemoted: ["moderator", "admin"],
    canDemote: ["owner", "admin"],
    canPromote: ["owner", "admin"],
    canRemove: ["owner", "admin", "moderator"],
    positionIcon,
  }),
  methods: {
    handleMemberOption(type) {
      switch (type) {
        case "moderator":
          this.changePosition("moderator");
          break;
        case "admin":
          this.changePosition("admin");
          break;
        case "demote":
          this.changePosition("member");

          break;
        case "kick":
          this.changeStatus("deleted");

          break;

        default:
          break;
      }
    },
    changePosition(position) {
      this.$setMemberPosition(this.id, position)
        .then((res) => {
          if (res.status) {
            window.location.reload();
          }
        })
        .catch((err) => {
          this.$notify.error({
            message: "Gagal mengubah posisi member",
            offset: 100,
            duration: 3500,
          });
        });
    },
    changeStatus(status) {
      this.$setMemberStatus(this.id, status)
        .then((res) => {
          if (res.status) {
            window.location.reload();
          }
        })
        .catch((err) => {
          this.$notify.error({
            message: "Gagal mengubah status member",
            offset: 100,
            duration: 3500,
          });
        });
    },
  },
};
