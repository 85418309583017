
export default {
  props: ["idListing", "owner_id", "idContent", "is_sold"],
  data: () => ({
    allowedRole: ["owner", "admin", "moderator"],
    memberMenuList: [
      {
        text: "Edit",
        command: "edit",
        icon: "bx-edit",
        allowedRole: [],
        style: "",
      },
      {
        text: "Tandai terjual",
        command: "sold",
        icon: "bx-money",
        allowedRole: [],
        style: "",
      },
      {
        text: "Hapus",
        command: "hapus",
        icon: "bx-trash",
        allowedRole: ["owner", "admin", "moderator"],
        style: "color: red",
      },
    ],
  }),
  created() {
    this.memberMenuList =
      this.owner_id === this.$store.state.auth.user.myProfile.id
        ? this.memberMenuList
        : this.memberMenuList.filter((el) =>
            el.allowedRole.includes(this.$store.state.auth.user.member.position)
          );
    this.checkSoldItem();
  },
  watch: {
    is_sold() {
      this.checkSoldItem();
    },
  },
  methods: {
    handleDropdownListing(val) {
      const communityID = this.$store.state.community.profile.id;
      switch (val) {
        case "edit":
          this.$goListing(
            `community/${communityID}/listing?edit=${this.idListing}`
          );
          break;
        case "sold":
          this.$nuxt.$emit("onClickSell", this.idContent, this.idListing);
          break;
        case "hapus":
          this.$nuxt.$emit("onClickDelete", this.idContent, "listing");
          // this.deleteListing();
          break;
        default:
          break;
      }
    },
    deleteListing() {
      this.$editQuestion(this.idContent, { is_active: 0 })
        .then((response) => {
          this.$notify.success({
            message: "Listing berhasil dihapus",
            offset: 100,
            duration: 3500,
            onClose: () => {
              window.location.reload();
            },
          });
        })
        .catch((err) => {
          this.$notify.error({
            message: "Listing gagal dihapus",
            offset: 100,
            duration: 3500,
          });
        });
    },
    checkSoldItem() {
      if (this.is_sold) {
        const hideMenu = ["edit", "sold"];
        this.memberMenuList = this.memberMenuList.filter(
          (el) => !hideMenu.includes(el.command)
        );
      }
    },
  },
};
